import React from "react";
import "./CertifiedBlog.css";
import HireD from "../../../Assets/Images/blog/HireD.jpg";
import certificate1 from "../../../Assets/Images/blog/certificate3.png";
import certificate2 from "../../../Assets/Images/blog/certificate4.png";
import insideHead from "../../../Assets/Images/blog/insidenew.png";
import { useNavigate } from "react-router-dom";
import certificatePdf from "../../../Assets/Images/certificate/certificate.pdf";
import { Helmet } from "react-helmet";

const CertifiedBlog = () => {
  const navigate = useNavigate();
  const goToContact = () => {
    navigate("/contact");
  };

  // Handler function to download the PDF
  const downloadPdf = () => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = certificatePdf;

    // Set the download attribute with a default filename
    link.setAttribute("download", "certificate.pdf");

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link element after triggering the download
    document.body.removeChild(link);
  };

  const CertifiedJsonLd = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "@id":
      "https://senwellexports.com/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001%3A2015-Certification",
    url: "https://senwellexports.com/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001%3A2015-Certification",
    headline:
      "Senwell Exports Reaches New Heights with AS9100D and ISO 9001:2015 Certification",
    description:
      "Senwell Exports’ AS9100D and ISO 9001:2015 certifications mark major milestones, enhancing quality management and driving customer satisfaction.",
    datePublished: "2024-07-11",
    mainEntityOfPage:
      "https://senwellexports.com/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001%3A2015-Certification",
    publisher: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        width: 82,
        height: 82,
      },
    },
    image: {
      "@type": "ImageObject",
      url: "https://senwellexports.com/static/media/outside.a293cc65243001ed39d9.png",
      width: 366,
      height: 200,
      caption:
        "Senwell Exports Reaches New Heights with AS9100D and ISO 9001:2015 Certification",
    },
    keywords:
      "AS9100D, ISO 9001:2015, Certification, Quality Management, Senwell Exports",
    articleSection: ["Certifications", "Quality Management"],
    inLanguage: "en-US",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Senwell Exports Reaches New Heights with AS9100D and ISO 9001:2015
          Certification
        </title>
        <meta
          name="description"
          content="Senwell Exports’ AS9100D and ISO 9001:2015 certifications mark major milestones, enhancing quality management and driving customer satisfaction."
        />
        <link
          rel="canonical"
          href="https://senwellexports.com/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001%3A2015-Certification"
        />
        <meta
        name="keywords"
        content="AS9100D Certification, ISO 9001:2015 Certification, Quality Management Systems, Aerospace Industry, Precision Components, Customer Focus, Continuous Improvement, Global Recognition, Risk Management, Operational Efficiency, Regulatory Compliance, Manufacturing Excellence, International Standards, Quality Assurance, Process Optimization, Innovation in Aerospace, Excellence in Manufacturing, Customer Satisfaction, Quality Standards, IAQG (International Aerospace Quality Group), Aerospace Supplier, Global Market Expansion, Product Safety, Continuous Innovation"
      />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Senwell Exports Reaches New Heights with AS9100D and ISO 9001:2015 Certification"
        />
        <meta
          property="og:description"
          content="Explore how Senwell Exports has achieved new milestones with its AS9100D and ISO 9001:2015 certifications. This blog post details the importance of these certifications and their impact on quality management and customer satisfaction."
        />
        <meta
          property="og:url"
          content="https://senwellexports.com/blog/Senwell-Exports-Reaches-New-Heights-with-AS9100D-and-ISO-9001%3A2015-Certification"
        />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/outside.a293cc65243001ed39d9.png"
        />
        <meta property="og:image:width" content="366" />
        <meta property="og:image:height" content="200" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(CertifiedJsonLd)}
        </script>
      </Helmet>
      <section className="innerBlogBody">
        <div style={{ left: "0", border: " white" }}>
          <div className="image-blog-header-blogCertificate ">
            <img src={insideHead} class="img-fluid" alt="..."></img>
            <div className="entry-title"></div>
          </div>

          <div className="container content-blog mt-2">
            <div className="entry-title">
              <h1 className="blogMain-header text-dark mt-5">
                Senwell Exports Reaches New Heights with AS9100D and ISO
                9001:2015 Certification
              </h1>
              <p className="blog-container-date text-dark">| 11 July, 2024 |</p>
            </div>
            <br />
            <p className="javaAppDevelopment">
              We are excited to share that,{" "}
              <a
                href="https://senwellexports.com/"
                target="_blank"
                className="link-blog"
              >
                Senwell Exports Private Limited
              </a>{" "}
              has recently obtained the esteemed AS9100D and ISO 9001:2015
              certifications. This accomplishment highlights our unwavering
              dedication to quality and excellence, reinforcing our commitment
              to upholding the highest standards in the aerospace industry.
            </p>
            <p className="fw-bold">
              We are proud to announce that Senwell Exports Private Limited is
              now an AS9100D certified company.
            </p>

            <p onClick={downloadPdf} className="text-center ">
              <a className="fw-bold downloadPdf">
                View / Download a Copy of Our AS9100D and ISO 9001:2015
                Certificate (PDF)
              </a>
            </p>
            <h2 className="entry-sub-title">
              Commitment to{" "}
              <a
                href="https://senwellexports.com/quality"
                target="_blank"
                className="text-decoration-none"
              >
                Quality
              </a>{" "}
              Management Systems
            </h2>
            <p>
              At Senwell Exports, quality is at the forefront of our operations.
              Our Company, now certified to both AS9100D and ISO 9001:2015
              standards, is recognized for its excellence in the design,
              development, and Precision components. These certifications
              reflect our pledge to maintain robust quality management systems
              that meet the stringent requirements of the aerospace industry.
            </p>

            <h2 className="entry-sub-title">
              Understanding AS9100D and ISO 9001:2015
            </h2>
            <p>
              <b>AS9100D </b> is a globally recognized quality management
              standard specifically designed for the aerospace sector. Developed
              by the International Aerospace Quality Group (IAQG), it
              incorporates all the elements of ISO 9001:2015 while adding
              additional aerospace-specific criteria. This ensures that
              organizations consistently deliver products and services that meet
              the high demands of the aerospace industry.
            </p>

            <p>
              <b>ISO 9001:2015 </b> is the world's most recognized quality
              management standard, applicable to any industry. It sets out the
              criteria for a quality management system and is based on
              principles such as strong customer focus, process approach, and
              continual improvement.
            </p>

            <h2 className="entry-sub-title">
              Benefits of AS9100D and ISO 9001:2015 Certification
            </h2>

            <ol>
              <li className="javaAppDevelopment2">
                <b>Enhanced Quality: </b>These certifications demonstrate our
                commitment to delivering products and services of the highest
                quality, ensuring we meet or exceed customer expectations.
              </li>
              <li className="javaAppDevelopment2">
                <b>Improved Efficiency: </b> Adherence to these standards helps
                us streamline our processes, optimize resource utilization, and
                improve overall operational efficiency
              </li>
              <li className="javaAppDevelopment2">
                <b>Compliance and Risk Management:</b>By following AS9100D and
                ISO 9001:2015 requirements, we effectively manage risks related
                to product safety, regulatory compliance, and customer
                satisfaction.
              </li>
              <li className="javaAppDevelopment2">
                <b>Global Recognition:</b>: These certifications are recognized
                worldwide, enhancing our reputation as a reliable supplier and
                opening doors to new markets.
              </li>
              <li className="javaAppDevelopment2">
                <b>Continuous Improvement:</b> : Both standards foster a culture
                of continuous improvement within our organization, driving
                innovation and excellence in all our operations.
              </li>
            </ol>

            <div className="container certificate-component">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src={certificate2}
                    class="img-fluid"
                    alt="certificate2"
                  ></img>
                </div>
                <div className="col-md-6">
                  <img
                    src={certificate1}
                    class="img-fluid"
                    alt="certificate1"
                  ></img>
                </div>
              </div>
            </div>

            <h2 className="entry-sub-title new-entry-title">
              What This Means for Our Customers
            </h2>
            <p className="javaAppDevelopment">
              Achieving AS9100D and ISO 9001:2015 certification means that,
              <a
                href="https://senwellexports.com/"
                target="_blank"
                className="text-decoration- fw-bold"
              >
                Senwell Exports
              </a>{" "}
              has met the rigorous quality management system requirements set
              forth by these internationally recognized standards. Our customers
              can trust that we have the necessary processes and procedures in
              place to consistently deliver high-quality services that meet
              their needs. This achievement is a testament to our commitment to
              excellence in manufacturing and our dedication to maintaining the
              highest standards of quality.
            </p>

            <p className="javaAppDevelopment">
              We look forward to leveraging these certifications to further
              enhance our services and continue providing exceptional value to
              our customers.
            </p>

            <img
              src={HireD}
              alt="HireDeveloper"
              className="HireDev cursor-pointer"
              style={{ cursor: "pointer", width: "100%", height: "100%" }}
              onClick={goToContact}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default CertifiedBlog;

import React, { useState } from "react";
import "./InnerOil.css";
import { useParams } from "react-router-dom";
import JsonData from "../../../../Products.json";
import OrderForm from "../../OrderForm/OrderForm";
import { Helmet } from "react-helmet";

const InnerOil = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);

  const { typeId } = useParams();
  const selectedType = JsonData.sections
    .flatMap((section) => section?.types || [])
    .find((type) => type.id === Number(typeId));

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };

  if (!selectedType) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <Helmet>
      <meta charSet="utf-8" />
      <title>{selectedType.title} {selectedType.title2}</title>
      {/* eslint-disable-next-line no-restricted-globals */}
      <link rel="canonical" href={`https://senwellexports.com${window.location.pathname}`} />
      <meta 
        name="description" 
        content={selectedType ? `Discover ${selectedType.title} ${selectedType.title2}, a premium product in the oil and gas industry.` : "Explore our top-quality oil and gas products."} 
      />
      </Helmet>
  
      <div className="Inner-oil-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              {loading && <div className="loader"></div>}
              <div className="service-block-6 mb-xl-30">
                <img
                  src={selectedType.imageInner}
                  alt={selectedType.title}
                  onLoad={handleImageLoad}
                  className="oilgas-fit"
                />
              </div>
            </div>

            <div
              className="col-lg-8 col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="service-block-6 mb-xl-30">
                <h2>{selectedType.title}</h2>
                <h2>{selectedType.title2}</h2> <br />
                {showPopup ? (
                  <div className="service-block mb-xl-30 position-relative">
                    <div className="text-start service-caption">
                      <button
                        className="btn btn btn-primary"
                        onClick={handleApplyNowClick}
                      >
                        Request a quotation
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="order-form-pop">
                    <div
                      className="order-form-overlay"
                      onClick={handleApplyNowClick}
                    ></div>
                    <OrderForm />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnerOil;

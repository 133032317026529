import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { EventsData } from "./event";
import "./event.css";
import eventbackground from "../../Assets/Images/Events/event-background.jpg";

const EventsSection = ({ title, events }) => {
  const navigate = useNavigate();

  return (
    <div>
      <h2 className="events-section-headings">{title}</h2>
      <div className="row">
        {events.map((event, index) => (
          <div className="col-md-4 my-3 events-cards" key={index}>
            <div
              className="blogLinks mb-4"
              onClick={() => navigate(`/events/${event.index}`, { state: { data: event.index } })}
            >
              <div className="Exportcard blogbox ">
                <div className="px-4 pt-4">
                  <img src={event.image} alt="event" className="blog-ig" />
                </div>
                <div className="card-body mnhgt-175 px-4">
                  <p className="card-text fs-6">{event.Date}</p>
                  <h4 className="card-title text-dark">{event.title}</h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Events = () => {
  const blogJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/events",
    url: "https://senwellexports.com/events",
    name: "Senwell Exports Pvt Ltd Events",
    description:
      "Explore the latest insights, updates, and events from Senwell Exports Pvt Ltd. Our Events covers industry trends, company events, and more.",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
  };
  const navigate = useNavigate();

  const currentDate = new Date();
  const thresholdDate = new Date("2025-03-09"); // Change to March 9th to move event after 8th

  const upcomingEvents = EventsData.filter((event, index) => index === 0 && currentDate < thresholdDate);
  const pastEvents = EventsData.filter((event, index) => index !== 0 || currentDate >= thresholdDate);


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Exports Pvt Ltd Events on Precision Machining</title>
        <meta
          name="description"
          content="Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd. Our blog covers industry trends, company news, and more."
        />
        <link rel="canonical" href="https://senwellexports.com/events" />
        <meta property="og:title" content="Events | Senwell Exports Pvt Ltd" />
        <meta property="og:description" content="Explore the latest insights, updates, and news from Senwell Exports Pvt Ltd." />
        <meta property="og:url" content="https://senwellexports.com/events" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <script type="application/ld+json">{JSON.stringify(blogJsonLd)}</script>
      </Helmet>

      <div className="event-banner">
        <img src={eventbackground} alt="Event Image" />
        <div className="text-overlay">Events</div>
      </div>

      <div className="mt-5">
        <section className="blog-section-2 my-4">
          <div className="container ">
            {upcomingEvents.length > 0 && <EventsSection title="Upcoming Events" events={upcomingEvents}/>}
            {pastEvents.length > 0 && <EventsSection title="Events" events={pastEvents} />}
          </div>
        </section>
      </div>
    </>
  );
};

export default Events;

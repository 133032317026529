import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EventsData } from "./event";
import "./event.css";
import { Helmet } from "react-helmet";
import bookAppoint from "../../Assets/Images/blog/bookAppoint.jpg";

const EventDescription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  useEffect(() => {
    const url = window.location.href.split("/");
    const updatedUrl = url[url.length - 1];
    const isValidURL = EventsData.some((data) => data.index === updatedUrl);

    if (!location.state && updatedUrl !== "" && !isValidURL) {
      navigate("/");
    }

    const Event = EventsData.find((data) =>
      data.index === (location.state ? location.state.data : updatedUrl)
    );

    if (Event) setData(Event);
  }, []);

  const goToScheduleCall = () => {
    navigate("/schedulecall");
  };

  return (
    <section className="event-description-container">
      {data && (
        <>
          {/* Dynamic Open Graph Meta Tags */}
          <Helmet>
            <title>{data.title || "Event Details"}</title>
            <link rel="canonical" href={window.location.href} />
            <meta 
              name="description" 
              content={data.seoDescription ? data.seoDescription.substring(0, 150): ''} 
            />

            <meta
              name="keywords"
              content={data.seoKeywords}
            />
            
            <meta property="og:title" content={data.title || "Event"} />
            <meta property="og:description" content={data.subtitle || ""} />
            <meta property="og:image" content={data.image || ""} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="article" />
          </Helmet>

          <div className="news-container">
            
            <img src={data.image} alt={data.title} className="bannerimg" />
           {/*<p
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            Image Source - {data.imageSource}
            </p>*/}
            <h1 style={{ textAlign: "start", fontSize: "2rem", fontWeight: "600",paddingLeft:"0.5rem",paddingTop:"1rem"}}>
              {data.title}
            </h1>
            <p
              dangerouslySetInnerHTML={{ __html: data.Description }}
              className="EventText"
              style={{ backgroundColor: "white" }}
            />
          </div>

          <img
            src={bookAppoint}
            alt="HireDeveloper"
            className="HireDev cursor-pointer"
            style={{ cursor: "pointer", width: "100%", height: "100%", marginTop: "20px" }}
            onClick={goToScheduleCall}
          />
        </>
      )}
    </section>
  );
};

export default EventDescription;

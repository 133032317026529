import React, { useState } from "react";
import ProductData from "../../../../../Products.json";
import { useParams } from "react-router-dom";
import OrderForm from "../../../OrderForm/OrderForm";
import "./SubInnerCable.css";

const InnerCable = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };

  const { typeId } = useParams();
  const AccessrSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData =
    AccessrSection.products[4] || [].find((item) => item.id === 205);
  const CableData = relevantData.CableProduct[0].BrassCopperProduct;

  const InnerCableData = CableData.filter(
    (product) => product.type_id == typeId
  );

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <section className="Sub-innerCable-Section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brass-subheader-content">
                <h1>{InnerCableData[0].type_name}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Inner-sectionsub mt-5" >
        <div className="container">
          <div className="row">
            {InnerCableData.map((item, index) => (
              <>
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="service-block-6 mb-xl-30">
                    {loading && <div className="loader"></div>}
                    <img
                      src={item.image}
                      alt={item.type_name}
                      className="sub-inner-img"
                      onLoad={handleImageLoad}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6">
                  <div className="service-block-6 mb-xl-30">
                    <h2>{item.type_name}</h2>
                    <p>{item.dec} </p>

                    <table
                      className="table table table-bordered"
                      style={{ marginTop: "2%" }}
                    >
                      <tbody>
                        {item.table &&
                          item.table.map((specification, index) => (
                            <tr key={index}>
                              <td>{specification.Type}</td>
                              <td>{specification.CZ}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {showPopup ? (
                      <div className="service-block mb-xl-30 position-relative">
                        <div className="text-start service-caption">
                          <button
                            className="btn btn btn-primary"
                            onClick={handleApplyNowClick}
                          >
                            Request a quotation
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="order-form-pop ">
                        <div
                          className="order-form-overlay "
                          onClick={handleApplyNowClick}
                        ></div>
                        <OrderForm />
                      </div>
                    )}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default InnerCable;

import React, { useState } from "react";
import "./SubInner.css";
import { useParams } from "react-router-dom";
import ProductData from "../../../../../Products.json";
import OrderForm from "../../../OrderForm/OrderForm";

const SubInner = () => {
  const [showPopup, setShowPopup] = useState(true);
  const handleApplyNowClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setShowPopup((prev) => !prev);
  };
  const { categoryId, typeId } = useParams();

  const fastnerSection = ProductData.sections.find(
    (section) => section.id == 2
  );

  const relevantData = fastnerSection.products[1] || {};
  const InsideRelevent = relevantData.insideProduct.filter(
    (item) => item.type_id == categoryId
  );

  let products = InsideRelevent[0]?.subType[0]?.products;
  const product = products.filter((item) => item.type_id == typeId);

  return (
    <>
      <div>
        {product?.map((item) => {
          return (
            <div>
              <section className="subInner-section" >
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="service-block-6 mb-xl-30">
                        <img src={item.image} alt="img" className="sub-inner-img" />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-6">
                      <div className="service-block-6 mb-xl-30">
                        <h2>{item.type_name}</h2>
                        {item.dec && <p>{item.dec}</p>}

                        {item.uses && (
                          <p>
                            <b>Uses: </b>
                            {item.uses}
                          </p>
                        )}
                        {item.features && (
                          <p>
                            <b>Features: </b>
                            {item.features}
                          </p>
                        )}
                        {item.standard && (
                          <p>
                            <b>Standard: </b>
                            {item.standard}
                          </p>
                        )}
                        {item.size && (
                          <p>
                            <b>Sizes: </b>
                            {item.size}
                          </p>
                        )}

                        {item.Threads && (
                          <p>
                            <b>Sizes: </b>
                            {item.Threads}
                          </p>
                        )}
                        {showPopup ? (
                          <div className="service-block mb-xl-30 position-relative">
                            <div className="text-start service-caption">
                              <button
                                className="btn btn btn-primary"
                                onClick={handleApplyNowClick}
                              >
                                Request a quotation
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="order-form-pop ">
                            <div
                              className="order-form-overlay "
                              onClick={handleApplyNowClick}
                            ></div>
                            <OrderForm />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SubInner;

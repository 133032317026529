import React from "react";
import "./OilandGas.css";
import { Link } from "react-router-dom";
import ProductData from "../../../Products.json";
import HomeVideo from "../../../Assets/Images/Video/oil&Gas.mp4";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";

// Create an object mapping image names to imported images
const images = {
  "Oil1.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil1.jpg"),
  "Oil2.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil2.jpg"),
  "Oil3.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil3.jpg"),
  "Oil4.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil4.jpg"),
  "Oil5.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil5.jpg"),
  "Oil6.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil6.jpg"),
  "Oil7.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil7.jpg"),
  "Oil8.jpg": require("../../../Assets/Images/Products/Oil&Gas/Oil8.jpg"),
};

const OilandGas = () => {
  const OilandGasSection = ProductData.sections.find(
    (section) => Number(section.id) === 3
  );
  if (!OilandGasSection) {
    return <div>Oil and Gas section not found</div>;
  }
  const typesData = OilandGasSection.types;

  const oilJsonLd = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://senwellexports.com/oil-gas",
    url: "https://senwellexports.com/oil-gas",
    name: "Oil and Gas Precision Machining | Senwell Exports Pvt Ltd",
    description:
      "Discover Senwell Exports Pvt Ltd’s commitment to excellence in the Oil and Gas sector through our innovative technologies and industry-leading solutions",
    inLanguage: "en-US",
    mainEntity: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      url: "https://senwellexports.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption:
          "Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune",
      },
    },
    video: {
      "@type": "VideoObject",
      name: "Oil and Gas Video",
      description:
        "A video showcasing the oil & gas of Senwell Exports Pvt Ltd.",
      uploadDate: "2024-09-26",
      contentUrl:
        "https://senwellexports.com/static/media/oil&Gas.f67a31493a8a8cdc4651.mp4",
      embedUrl: "https://senwellexports.com/oil-gas#video",
      duration: "PT6S",
      width: 1280,
      height: 720,
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Oil and Gas Precision Machining | Senwell Exports Pvt Ltd</title>
        <meta
          name="description"
          content="Discover Senwell Exports Pvt Ltd’s commitment to excellence in the Oil and Gas sector through our innovative technologies and industry-leading solutions."
        />
        <link rel="canonical" href="https://senwellexports.com/oil-gas" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Oil and Gas | Senwell Exports Pvt Ltd"
        />
        <meta
          property="og:description"
          content="We have multi-axis machining centers and high accuracy CNC machines. VERY SUCCESSFUL SPECIALISTS: Highly qualified professionals with a wealth of knowledge in the oil and gas sector are in charge of developing new products and improving the quality and operational dependability of those already in production."
        />
        <meta property="og:url" content="https://senwellexports.com/oil-gas" />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/I2.74e9364e2c497e4e5f60.png"
        />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">{JSON.stringify(oilJsonLd)}</script>
      </Helmet>
        <div className="video-container oilgas-video "style={{paddingTop:"5.6rem"}}>
          <ReactPlayer
            url={HomeVideo}
            width="100%"
            height="100%"
            controls={false}
            playing
            loop
            muted
          />
        </div>

      <section className="oil-gas-section">
        <div className="container">
          <div className="row" style={{ margin: "0", padding: "0" }}>
            <div className="container">
              <div className="oil-header ">
                <h3>{OilandGasSection.name}</h3>
              </div>
            </div>

            <ul className="oil-ul">
              <li>
                <strong>MULTI-AXIS MACHINING CENTERS:</strong>
                <span className="oilandgas-text">{OilandGasSection.Multi}</span>
              </li>
              <li>
                <strong>SECONDARY PROCESSES:</strong>
                <span className="oilandgas-text">
                  {OilandGasSection.secondary}
                </span>
              </li>
              <li>
                <strong>SPECIAL THREADS & PROFILES:</strong>
                <span className="oilandgas-text">{OilandGasSection.S}</span>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="container">
          <div className="row" style={{ rowGap: "57px" }}>
            {typesData.map((type) => (
              <div key={type.id} className="col-lg-4 col-md-6">
                <Link
                  to={`/oil-gas/${type.id}`}
                  className="oil-gas-image-container"
                >
                  <img
                    src={images[type.image]}
                    alt={type.title}
                    className="oil-gas-product-image"
                  />
                  <div className="oil-gas-overlay-text">
                    <div className="oil-gas-overlay-inner-text">
                      <span>{type.title}</span> <br />
                      <span>({type.title2})</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OilandGas;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import newsData from "./newsData.json";

function SpecificNews() {
  const param = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const newsItem = newsData.NewsData.find((item) => item.route === param.newsName);
    setData(newsItem);
  }, [param.newsName]);

  if (!data) return <div>Loading...</div>;

  const articleURL = `https://senwellexports.com/news/${param.newsName}`;


  return (
    <>
      {/* Dynamic Open Graph Meta Tags for Each News Article and SEO */}
      <Helmet>
      
        <meta charSet="utf-8" />
        <title>{data.seotitle}</title>
        <link rel="canonical" href={articleURL} />
        <meta name="description" content={data.seoDescription.substring(0, 150)} />
        <meta
          name="keywords"
          content={data.seoKeywords}
        />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.seodescription} />
        <meta property="og:image" content={data.InnerImage} />
        <meta property="og:url" content={articleURL} />
        <meta property="og:type" content="article" />
      </Helmet>

      <div className="news-container">
        <img src={data.InnerImage} alt={data.title} className="innerImage" style={{paddingTop:"8 rem"}}/>
        <p style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
        {data.imageSource && <p>Image Source - {data.imageSource}</p>}

        </p>
        <h1 style={{textAlign:"start", fontSize:"2rem",paddingLeft:"1rem"}}>
          {data.title}
        </h1>
        <p dangerouslySetInnerHTML={{ __html: data.description }} style={{ fontSize: "1.1rem" }} />
      </div>
    </>
  );
}

export default SpecificNews;

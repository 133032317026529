import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import HomeVideo from '../../../Assets/Images/Video/AeroSpaceVideo.mp4';
import ProductData from '../../../Products.json';
import OrderForm from '../OrderForm/OrderForm';
import './AeroSpace.css';

const images = {
	'Aero58.jpg': require('../../../Assets/Images/Products/Aerospace/Aero58.jpg'),
	'Aero59.jpg': require('../../../Assets/Images/Products/Aerospace/Aero59.jpg'),
	'Aero60.jpg': require('../../../Assets/Images/Products/Aerospace/Aero60.jpg'),
	'Aero61.jpg': require('../../../Assets/Images/Products/Aerospace/Aero61.jpg'),
	'Aero62.jpg': require('../../../Assets/Images/Products/Aerospace/Aero62.jpg'),
	'Aero63.jpg': require('../../../Assets/Images/Products/Aerospace/Aero63.jpg'),
	'Aero64.jpg': require('../../../Assets/Images/Products/Aerospace/Aero64.jpg'),
	'Aero65.jpg': require('../../../Assets/Images/Products/Aerospace/Aero65.jpg'),
	'Aero66.jpg': require('../../../Assets/Images/Products/Aerospace/Aero66.jpg'),
	'Aero67.jpg': require('../../../Assets/Images/Products/Aerospace/Aero67.jpg'),
	'Aero68.jpg': require('../../../Assets/Images/Products/Aerospace/Aero68.jpg'),
	'Aero69.jpg': require('../../../Assets/Images/Products/Aerospace/Aero69.jpg'),
	'Aero70.jpg': require('../../../Assets/Images/Products/Aerospace/Aero70.jpg'),
	'Aero71.jpg': require('../../../Assets/Images/Products/Aerospace/Aero71.jpg'),
	'Aero72.jpg': require('../../../Assets/Images/Products/Aerospace/Aero72.jpg'),
	'Aero73.jpg': require('../../../Assets/Images/Products/Aerospace/Aero73.jpg'),
	'Aero74.jpg': require('../../../Assets/Images/Products/Aerospace/Aero74.jpg'),
	'Aero32.jpg': require('../../../Assets/Images/Products/Aerospace/Aero32.jpg'),
	'Aero33.jpg': require('../../../Assets/Images/Products/Aerospace/Aero33.jpg'),
	'Aero75.jpg': require('../../../Assets/Images/Products/Aerospace/Aero75.jpg'),
	'Aero76.jpg': require('../../../Assets/Images/Products/Aerospace/Aero76.jpg'),
	'Aero77.jpg': require('../../../Assets/Images/Products/Aerospace/Aero77.jpg'),
	'Aero78.jpg': require('../../../Assets/Images/Products/Aerospace/Aero78.jpg'),
	'Aero79.jpg': require('../../../Assets/Images/Products/Aerospace/Aero79.jpg'),
	'Aero80.jpg': require('../../../Assets/Images/Products/Aerospace/Aero80.jpg'),
	'Aero81.jpg': require('../../../Assets/Images/Products/Aerospace/Aero81.jpg'),
	// 'Aero82.jpg': require('../../../Assets/Images/Products/Aerospace/Aero82.jpg'),
	'Aero83.jpg': require('../../../Assets/Images/Products/Aerospace/Aero83.jpg'),
	'Aero84.jpg': require('../../../Assets/Images/Products/Aerospace/Aero84.jpg'),
	'Aero85.jpg': require('../../../Assets/Images/Products/Aerospace/Aero85.jpg'),
	'Aero86.jpg': require('../../../Assets/Images/Products/Aerospace/Aero86.jpg'),
	'Aero87.jpg': require('../../../Assets/Images/Products/Aerospace/Aero87.jpg'),
	'Aero88.jpg': require('../../../Assets/Images/Products/Aerospace/Aero88.jpg'),
	'Aero89.jpg': require('../../../Assets/Images/Products/Aerospace/Aero89.jpg'),
	'Aero90.jpg': require('../../../Assets/Images/Products/Aerospace/Aero90.jpg'),
	'Aero91.jpg': require('../../../Assets/Images/Products/Aerospace/Aero91.jpg'),

	'Aero1.jpg': require('../../../Assets/Images/Products/Aerospace/Aero1.jpg'),
	'Aero2.jpg': require('../../../Assets/Images/Products/Aerospace/Aero2.jpg'),
	'Aero3.jpg': require('../../../Assets/Images/Products/Aerospace/Aero3.jpg'),
	'Aero4.jpg': require('../../../Assets/Images/Products/Aerospace/Aero4.jpg'),
	'Aero5.jpg': require('../../../Assets/Images/Products/Aerospace/Aero5.jpg'),
	'Aero6.jpg': require('../../../Assets/Images/Products/Aerospace/New-6.jpg'),
	'Aero7.jpg': require('../../../Assets/Images/Products/Aerospace/Aero7.jpg'),
	'Aero10.jpg': require('../../../Assets/Images/Products/Aerospace/Aero10.jpg'),
	'Aero12.jpg': require('../../../Assets/Images/Products/Aerospace/Aero12.jpg'),
	'Aero13.jpg': require('../../../Assets/Images/Products/Aerospace/Aero13.jpg'),
	'Aero14.jpg': require('../../../Assets/Images/Products/Aerospace/Aero14.jpg'),
	'Aero15.jpg': require('../../../Assets/Images/Products/Aerospace/Aero15.jpg'),
	'Aero17.jpg': require('../../../Assets/Images/Products/Aerospace/Aero17.jpg'),
	'Aero18.jpg': require('../../../Assets/Images/Products/Aerospace/Aero18.jpg'),
	'Aero21.jpg': require('../../../Assets/Images/Products/Aerospace/Aero21.jpg'),
	'Aero23.jpg': require('../../../Assets/Images/Products/Aerospace/Aero23.jpg'),
	'Aero24.jpg': require('../../../Assets/Images/Products/Aerospace/Aero24.jpg'),
	'Aero27.jpg': require('../../../Assets/Images/Products/Aerospace/Aero27.jpg'),
	'Aero28.jpg': require('../../../Assets/Images/Products/Aerospace/Aero28.jpg'),
};

const AeroSpace = () => {
	const [showPopup, setShowPopup] = useState(true);

	const handleApplyNowClick = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		setShowPopup((prev) => !prev);
	};

	const AccessrSection = ProductData.sections.find((section) => section.id === 1);

	// Ensure AccessrSection and products exist
	const AeroSpaceProduct = AccessrSection ? AccessrSection.products : [];

	const aeroJsonLd = {
		'@context': 'https://schema.org',
		'@type': 'WebPage',
		'@id': 'https://senwellexports.com/aerospace-industries',
		url: 'https://senwellexports.com/aerospace-industries',
		name: 'Aerospace Precision Machining | Senwell Exports Pvt Ltd',
		description:
			"Discover Senwell Exports Pvt Ltd's commitment to excellence in Aerospace Industries through our advanced technologies and innovative solutions.",
		inLanguage: 'en-US',
		mainEntity: {
			'@type': 'Organization',
			name: 'Senwell Exports Pvt Ltd',
			url: 'https://senwellexports.com/',
			logo: {
				'@type': 'ImageObject',
				url: 'https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png',
				caption: 'Senwell Exports Pvt Ltd | AS 9100D Certified Manufacturing Company | Pune',
			},
		},
		video: {
			'@type': 'VideoObject',
			name: 'Aerospace Precision Machining Video',
			description:
				'A video showcasing the precision machining capabilities of Senwell Exports Pvt Ltd in the aerospace industry.',
			uploadDate: '2024-09-26',
			contentUrl: 'https://senwellexports.com/static/media/AeroSpaceVideo.cef8b9a50b7e33a29ff8.mp4',
			embedUrl: 'https://senwellexports.com/aerospace-industries#video',
			duration: 'PT6S',
			width: 1280,
			height: 720,
		},
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Aerospace Precision Machining | Senwell Exports Pvt Ltd</title>
				<meta
					name="description"
					content="Discover Senwell Exports Pvt Ltd's commitment to excellence in Aerospace Industries through our advanced technologies and innovative solutions."
				/>
				<link rel="canonical" href="https://senwellexports.com/aerospace-industries" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Aerospace Precision Machining | Senwell Exports Pvt Ltd" />
				<meta
					property="og:description"
					content="Discover Senwell Exports Pvt Ltd's commitment to excellence in Aerospace Industries through our advanced technologies and innovative solutions."
				/>
				<meta property="og:url" content="https://senwellexports.com/aerospace-industries" />
				<meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
				<meta property="og:image" content="https://senwellexports.com/static/media/I1.9472b29744cf2e95269f.png" />
				<meta property="og:image:width" content="400" />
				<meta property="og:image:height" content="300" />
				<meta property="og:image:type" content="image/png" />
				<script type="application/ld+json">{JSON.stringify(aeroJsonLd)}</script>
			</Helmet>
			<div className="video-container" style={{paddingTop:"5.6rem"}}>
				<ReactPlayer url={HomeVideo} width="100%" height="100%" controls={false} playing loop muted />
			</div>
			<section className="Aerospace-section-2">
				<div className="container">
					<div className="row" >
						<div className="container">
							<div className="aerospace-product-head my-4 mt-0 mb-0	">
								<h3>Aerospace</h3>
							</div>
						</div>

						<div className="container mt-5 mb-5">
							{AeroSpaceProduct.length > 0 ? (
								<div className="row g-5">
									{AeroSpaceProduct.map((product) => (
										<div key={product.id} className="col-sm-4 col-lg-3 col-md-3">
											<div className="image-container">
												<img src={images[product.image]} alt={product.title} className="product-image" />
												<div className="overlay-text">
													<div className="overlay-inner-text">
														<span>{product.title}</span> <br />
														<span>({product.title2})</span>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							) : (
								<p>No products available</p>
							)}
						</div>
					</div>
				</div>
				<div className="q-center">
					{showPopup ? (
						<div className="service-block mb-xl-30 position-relative">
							<div className="text-start service-caption">
								<button className="btn btn btn-primary" onClick={handleApplyNowClick}>
									Request a quotation
								</button>
							</div>
						</div>
					) : (
						<div className="order-form-pop ">
							<div className="order-form-overlay " onClick={handleApplyNowClick}></div>
							<OrderForm />
						</div>
					)}
				</div>
			</section>
		</>
	);
};

export default AeroSpace;

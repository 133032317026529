import React from "react";
import "./HomeProduct.css";
import { Link } from "react-router-dom";
import Translation from "../../i18n";
import ProductItem from "../HomeProduct/HomeProducts.json";

function Product() {
  const images = {
    Aero45: require("../../Assets/Images/Products/Aerospace/Aero45.jpg"),
    Aero46: require("../../Assets/Images/Products/Aerospace/Aero46.jpg"),
    Aero58: require("../../Assets/Images/Products/Aerospace/Aero58.jpg"),
    Aero59: require("../../Assets/Images/Products/Aerospace/Aero59.jpg"),
    Aero61: require("../../Assets/Images/Products/Aerospace/Aero61.jpg"),
    Aero62: require("../../Assets/Images/Products/Aerospace/Aero62.jpg"),
    Aero66: require("../../Assets/Images/Products/Aerospace/Aero66.jpg"),
    Aero67: require("../../Assets/Images/Products/Aerospace/Aero67.jpg"),
    Aero68: require("../../Assets/Images/Products/Aerospace/Aero68.jpg"),
    Aero70: require("../../Assets/Images/Products/Aerospace/Aero70.jpg"),
    Aero72: require("../../Assets/Images/Products/Aerospace/Aero72.jpg"),
    Aero91: require("../../Assets/Images/Products/Aerospace/Aero91.jpg"),
  };
  const { t, i18n } = Translation();
  return (
    <>
      <section className="product-section ">
        <div className="container">
          <div className="row justify-content-center product-row">
            <h1 className="mt-3 mb-1">
              {t("our")} <span>{t("Products")}</span>
            </h1>

            <div className="home-products-container mt-5 mb-5">
              <div className="row g-5">
                {ProductItem.map((product) => (
                  <div key={product.id} className="col-lg-4 col-md-6 d-flex justify-content-center">
                    <Link
                      to="/aerospace-industries"
                      className="home-product-image-container"
                    >
                      <img
                        src={images[product.image]}
                        alt={product.title}
                        className="home-product-image"
                      />
                      <div className="home-overlay-text">
                        <div className="home-overlay-inner-text">
                          <span>{product.title}</span> <br />
                          <span>(Material-{product.material})</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Product;

import React from "react";
// import "./CncPrecisionMachining.css";
import HireD from "../../Assets/Images/blog/HireD.jpg"; 
import BooldDonation1 from "../../Assets/Images/Events/BooldDonation1.jpg";
import BooldDonation2 from "../../Assets/Images/Events/BooldDonation2.jpg";
import bookAppoint from "../../Assets/Images/blog/bookAppoint.jpg";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function BooldDonation() {
  const navigate = useNavigate();

  const goToContact = () => {
    navigate("/contact");
  };

  const BooldDonationJsonLd = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "@id":
      "https://senwellexports.com/events/Empower-Women-and-Save-Lives-by-Joining-Our-Blood-Donation-Drive-on-Women-Day",
    url: "https://senwellexports.com/events/Empower-Women-and-Save-Lives-by-Joining-Our-Blood-Donation-Drive-on-Women-Day",
    headline:
      "Celebrate Women’s Day by Donating Blood and Saving Lives!",
    description:
      "Discover how precision manufacturing is crucial for delivering high-quality products. This blog post explores the key aspects and benefits of precision machining across sectors like aerospace, oil and gas, defense, automotive, energy, and consumer electronics.",
    datePublished: "2024-08-10",
    mainEntityOfPage:
      "https://senwellexports.com/events/Empower-Women-and-Save-Lives-by-Joining-Our-Blood-Donation-Drive-on-Women-Day",

    publisher: {
      "@type": "Organization",
      name: "Senwell Exports Pvt Ltd",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellexports.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        width: 82,
        height: 82,
      },
    },
    image: {
      "@type": "ImageObject",
      url: "https://senwellexports.com/static/media/BooldDonation1.30fc5700258c47b2e126.jpg",
      width: 366,
      height: 200,
      caption:
        "Celebrate Women’s Day by Donating Blood and Saving Lives!",
    },
    keywords:
      "Blood Donation Drive, Women’s Day, Empower Women, Save Lives, International Women’s Day, Blood Donation Event, Donate Blood, Health and Safety, Community Care, Women’s Health, Women Empowerment, Support Women, Life-saving Act, Volunteering Opportunities, Kharadi Pune Event, Senwell Group, Charity Event, Donate Blood Pune, Blood Donation Benefits, Health Support, Emergency Blood Donation, Compassion and Resilience, Blood Donation Certificate, Healthy Snacks Post-Donation, Thank You Gift for Donors, Women in Healthcare, Blood Donation Heroes, Give Blood, Save Lives, Support Community Events, Make a Difference on Women’s Day",
    
    inLanguage: "en-US",
  };
  const goToScheduleCall = () => {
    navigate("/schedulecall");
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Celebrate Women’s Day by Donating Blood and Saving Lives!
        </title>
        <meta
          name="description"
          content="In celebration of Women’s Day, Senwell Group is hosting a Blood Donation Drive to empower women and save lives. Let’s come together and make a difference!"
        />
        <link
          rel="canonical"
          href="https://senwellexports.com/events/Empower-Women-and-Save-Lives-by-Joining-Our-Blood-Donation-Drive-on-Women-Day"
        />
        <meta
        name="keywords"
        content="Blood Donation Drive, Women’s Day, Empower Women, Save Lives, International Women’s Day, Blood Donation Event, Donate Blood, Health and Safety, Community Care, Women’s Health, Women Empowerment, Support Women, Life-saving Act, Volunteering Opportunities, Kharadi Pune Event, Senwell Group, Charity Event, Donate Blood Pune, Blood Donation Benefits, Health Support, Emergency Blood Donation, Compassion and Resilience, Blood Donation Certificate, Healthy Snacks Post-Donation, Thank You Gift for Donors, Women in Healthcare, Blood Donation Heroes, Give Blood, Save Lives, Support Community Events, Make a Difference on Women’s Day "
      />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Celebrate Women’s Day by Donating Blood and Saving Lives!"
        />
        <meta
          property="og:description"
          content="In celebration of Women’s Day, Senwell Group is hosting a Blood Donation Drive to empower women and save lives. Let’s come together and make a difference!"
        />
        <meta
          property="og:url"
          content="https://senwellexports.com/events/Empower-Women-and-Save-Lives-by-Joining-Our-Blood-Donation-Drive-on-Women-Day"
        />
        <meta property="og:site_name" content="Senwell Exports Pvt Ltd" />
        <meta
          property="og:image"
          content="https://senwellexports.com/static/media/BooldDonation1.30fc5700258c47b2e126.jpg"
        />
        <meta property="og:image:width" content="366" />
        <meta property="og:image:height" content="200" />
        <meta property="og:image:type" content="image/png" />
        <script type="application/ld+json">
          {JSON.stringify(BooldDonationJsonLd)}
        </script>
      </Helmet>


      <section className="innerBlogBody">
        <div style={{ left: "0", border: "white" }}>
          <div className="image-blog-header-blogCertificate ">
            <img
              src={BooldDonation1}
              className="img-fluid pt-0"
              alt="Inside Head"
              style={{ width: "100%"}}
            />
            <div className="entry-title"></div>
          </div>

          <div className="container content-blog mt-2">
            <div className="entry-title">
              <h1 className="blogMain-header text-dark m-0 p-4">
              Empower Women and Save Lives by Joining Our Blood Donation Drive on Women’s Day!

              </h1>
             
            </div>
           
            <p className="javaAppDevelopment">
                On the occasion of International Women’s Day, Senwell Group <a  href="https://senwellsys.com/"
                target="_blank"
                title="Senwell Group">Senwell Group</a> is proud to announce an impactful initiative: a Blood Donation Drive dedicated to honoring the incredible contributions of women to society while also making a tangible difference in the lives of those in need.
            </p>

            <p className="javaAppDevelopment">
                Women embody strength, compassion, and resilience. These traits extend beyond the workplace into communities and beyond, inspiring positive change. To celebrate this spirit, we invite all employees, partners, and the local community to join us for a meaningful event that not only supports women but also directly helps save lives. market.
            </p>
            <h2> Why Blood Donation?</h2>
            
            <p className="javaAppDevelopment">
                Blood donation is one of the most impactful ways to contribute to the well-being of others. Every year, millions of lives are saved thanks to the selfless donations of people like you. By donating blood, you're providing an invaluable resource to those in need, from accident victims to cancer patients, and even during emergencies. Moreover, women often play a pivotal role in such life-saving efforts, and we want to honor that commitment by encouraging everyone, especially women, to participate in this noble cause.
            </p>

            <section id="blood-donation-drive">
                <div>
                    <div >
                    <div className="row">
                        {/* Left Section - Description */}
                        <div className="col-lg-6 my-2 col-md-12 col-sm-12 ">
                        <div >
                            <p><strong>Event Details:</strong></p>
                            <ul>
                            <li style={{ listStyleType: "disc" }}>
                                <strong>Date:</strong> Saturday, 8 March 2025
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                <strong>Time:</strong> 9:00 am to 4:00 pm
                            </li>
                            <li style={{ listStyleType: "disc" }}>
                                <strong>Venue:</strong> Senwell Group Private Limited, Gera Imperium Alpha, Near EON IT Park, Kharadi, Pune.
                            </li>
                            </ul>
                            <p>
                            This Blood Donation Drive will be held at Kharadi, Pune, where a
                            team of qualified medical professionals will be available to assist
                            with the blood donation process. We have made sure the event is
                            designed to be a comfortable and supportive experience for all
                            participants.
                            </p>
                            <p>
                            For those who may be new to blood donation or want more information beforehand, we will be offering a brief orientation session prior to donations. This will provide valuable insights into the importance of blood donation and the impact it has on those in need.
                            </p>
                            <p>
                            We encourage everyone, regardless of experience with blood donation, to participate and make a difference. Your contribution could help save lives and improve the well-being of individuals in need.
                            </p>
                        </div>
                        </div>
                        {/* Right Section - Image */}
                        <div className="col-lg-6 my-2 col-md-12 col-sm-12 ser-img">
                        <img src={BooldDonation2} draggable={false} alt="Blood Donation Drive" className="ser-left-img" />
                        </div>
                    </div>
                    </div>
                </div>
            </section><br />

            <h2>How You Can Get Involved?</h2><br />
            <ul >
                <li style={{listStyleType: "disc"}}>
                <strong>Donate Blood –</strong>  It takes just a few minutes to make a difference. All healthy individuals are encouraged to donate.
                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Spread the Word –</strong> Share the event with colleagues, friends, and family to increase participation.
                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Volunteer –</strong>  Support our team in coordinating the event and ensure everything runs smoothly.
                </li>
            </ul>

            <p>
                For those unable to donate, we encourage you to attend, support, and spread awareness about the importance of blood donation.
            </p><br />

            <h2>Why Women's Day?</h2><br />

            <p>
                While this event is open to everyone, we are especially focused on celebrating women's health and empowerment during this time. Women have always been at the forefront of community care and compassion, and this Blood Donation Drive is a small way to show our gratitude. Whether donating blood, volunteering, or simply supporting, your participation speaks volumes about the collective power we hold in creating positive change.

            </p><br />

            <h2>Health and Safety Protocols</h2><br />
            <p>
                At Senwell Group, we care about your health and safety. To ensure that the event is safe for everyone, we will adhere to strict health guidelines, with professional staff overseeing all aspects of the blood donation process. Rest assured that we will prioritize your comfort and well-being throughout the event.
            </p><br />

            <h2>Benefits for Donors</h2><br />
            
            <ul >
                <li style={{listStyleType: "disc"}}>
                <strong>Lifetime Blood Support:</strong>  Donors will receive free blood transfusions for life, ensuring access to blood when needed.
                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Family Assistance:</strong>  In case a donor's parent requires blood, it will be provided at a discounted rate, offering support during critical times.
                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Blood Service:</strong>  24 Hours Available.
                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Special Gift:</strong> Receive a token of appreciation for your generous act.
                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Healthy Snacks:</strong> Enjoy nutritious refreshments post-donation to rejuvenate and replenish.

                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Certificate of Appreciation:</strong> Acknowledge your invaluable support with a personalized certificate.

                </li>
                <li style={{listStyleType: "disc"}}>
                <strong>Be a Hero:</strong> Your act of donating blood profoundly impacts and transforms lives.

                </li>
            </ul><br />

            <h2>A Small Act, A Big Impact</h2><br />

            <p>
                Your contribution, whether big or small, has the potential to save lives. The act of blood donation is simple but powerful, and it aligns perfectly with the values of compassion, solidarity, and empowerment that are celebrated on International Women’s Day.
            </p>

            <p>
                By participating in this event, we can all make a lasting impact on those who need it most. Let’s honor the women in our lives by stepping up to make a difference.
            </p>
            <p>
                Join us in our mission to make this Women’s Day a special and impactful occasion. Together, we can empower women and save lives with a simple, life-changing act. Let’s work hand-in-hand to ensure that women, families, and communities around the world have access to the resources they need for a brighter future.

            </p>
            <p>
                For more information about the event or to <a href="https://docs.google.com/forms/d/e/1FAIpQLSemy6hxgrlhtr8lLdD8tio80OfD2atHW-NwZFe_SYM8nKipNQ/viewform" target="_blank"
                title="Registration Link">register</a> as a donor. Also, check our <a href="https://www.linkedin.com/company/senwell-group-private-limited/posts/?feedView=all" target="_blank"
                title="Social media">social media pages</a> for updates!
            </p><br />

            <img
            src={bookAppoint}
            alt="HireDeveloper"
            className="HireDev cursor-pointer"
            style={{ cursor: "pointer", width: "100%", height: "100%", marginTop: "20px" }}
            onClick={goToScheduleCall}
          />
          </div>
        </div>
      </section>
    </>
  );
}

export default BooldDonation;
